
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
export  const routeArr = [
 /* {
    name:"首页",
    path: "index",
    component: () => (import("../views/rt_index.vue"))
  }, {
    name:"副页",
    path: "home",
    component: () => (import("../views/rt_home.vue"))
  }, */ /* {
    name:"管理员管理",
    path: "manage",
    component: () => (import("../page/index/manage/uManage.vue"))
  }, */ /* {
    name:"商品分类",
    path: "cate",
    component: () => (import("../page/index/cate/uCate.vue"))
  }, {
    name:"商品规格",
    path: "specs",
    component: () => (import("../page/index/specs/uSpecs.vue"))
  }, {
    name:"商品管理",
    path: "goods",
    component: () => (import("../page/index/goods/uGoods.vue"))
  }, {
    name:"轮播图管理",
    path: "banner",
    component: () => (import("../page/index/banner/uBanner.vue"))
  }, {
    name:"秒杀活动",
    path: "seckill",
    component: () => (import("../page/index/seckill/uSeckill.vue"))
  }, {
    name:"会员管理",
    path: "member",
    component: () => (import("../page/index/member/uMember.vue"))
  }, */
]
const routes = [{
  path: "./",
  component: () => (import('../views/rt_index.vue')),
  meta: { title: '首页' }
},
{
  path: "/index",
  component: () => (import('../views/rt_index.vue')),
  meta: { title: '首页' }
},{
  path: "/about",
  component: () => (import('../views/rt_about.vue')),
  meta: { title: '关于我们' }
},{
  path: "/product",
  component: () => (import('../views/rt_product.vue')),
  meta: { title: '产品介绍' }
},{
  path: "/club",
  component: () => (import('../views/rt_club.vue')),
  meta: { title: '火星玩家俱乐部' }
},{
  path: "/join",
  component: () => (import('../views/rt_join.vue')),
  meta: { title: '招商加盟' }
},{
  path: "/other",
  component: () => (import('../views/rt_other.vue')),
  meta: { title: '其他动态' }
},{
  path: "/productMore",
  component: () => (import('../views/rt_productMore.vue')),
  meta: { title: '品牌介绍' }
},
{
  path: "/describe",
  component: () => (import('../views/rt_describe.vue')),
  meta: { title: '产品描述' }
},
{
  path: "/clubMore",
  component: () => (import('../views/rt_clubMore.vue')),
  meta: { title: '俱乐部介绍' }
},
{
  path: "/clauseMore",
  component: () => (import('../views/rt_clauseMore.vue')),
  meta: { title: '服务条款' }
},
{
  path: "/otherMore",
  component: () => (import('../views/rt_otherMore.vue'))
},
{
  path:"*",
  redirect: '/index'
}

]
const router = new Router({
  routes,
});
/* import store from "../store/index"
router.beforeResolve((to,from,next)=>{
  if(to.path==="/index"){
    next();
  }else if(store.state.data.userInfo?.token){
   if(store.state.data.userInfo.menus_url.find(item=>to.path.includes(item) || to.path==="/home") ){
    next()
   }else{
    next("/home")
   }
  }else{
    next("/index")
  }
}) */
export default router;
<template>
  <div id="app">
  <HeaderNav />
    <router-view>
    </router-view>
    <FootNav />
  </div>
</template>
<script type='text/ecamscript-6'>
import HeaderNav from './components/headerNav'
import FootNav from './components/footNav'
export default{
  name:'APP',
  components:{
    HeaderNav,
    FootNav
  }
}
</script>
<style lang="less">
#app {
  height: 100%;
}
.justify-align {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.left-justifying {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.content-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

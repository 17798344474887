import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/reset.css";
import "./components/index.js";
import axios from 'axios'

/* import "./filters/index.js"; */
/* import "./less/element.scss"; */
Vue.config.productionTip = false
import Video from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$http = axios

Vue.prototype.$video = Video
import store from "./store/index";
Vue.use(ElementUI)

router.beforeEach((to, from, next) => {
	console.log('to', to)
	const basicTitle = localStorage.getItem('basicTitle')
	if(!basicTitle || basicTitle == '') {
		document.title = to.meta.title + '_中山市锐图科技有限公司';
		return
	}
  if (to.meta.title) {
    document.title = to.meta.title + '_' + basicTitle;
  } else {
    document.title = '中山市锐图科技有限公司';
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

<template>
   <div class='container' style="position: fixed;z-index: 99;background: rgba(0, 0, 0, 0.3);width: 100%;">

      <ul>
         <a :href="logo_url">
            <img :src="'https://mars-players.cn' + info" style="height: 80px;margin-right: 50px;" />
         </a>
         <!-- <li>LOGO</li> -->

         <!-- <li :class="[Inv==0?'show':'']" @click="goindex">首页</li>
      <li :class="[Inv==1?'show':'']" @click="gohome">关于我们</li>
      <li :class="[Inv==2?'show':'']" @click="goproduct">产品介绍</li>
      <li :class="[Inv==3?'show':'']" @click="goclub">火星玩家俱乐部</li>
      <li :class="[Inv==4?'show':'']" @click="gojoin">招商加盟</li>
      <li :class="[Inv==5?'show':'']" @click="goother">其他动态</li> -->

         <li v-for="(item, index)  in navList" @click="goUrlPage(item.path)" :class="activePath == item.path ? 'show' : ''"
            :key="index">{{ item.name }}</li>
      </ul>
   </div>
</template>
<script>
export default {
   data() {
      return {
         Inv: 0,
         info: '',
         activePath: '',
         navList: [{ name: '首页', path: '/index'}, { name: '关于我们', path: '/about' }, { name: '产品介绍', path: '/product' },
         { name: '火星玩家俱乐部', path: '/club' }, { name: '招商加盟', path: '/join' }, { name: '其他动态', path: '/other' }],
         logo_url: '',
      };
   },
   created() {
      var query = window.location.hash.split('#') // //获得了当前链接的中?号后的参数
      console.log('query', query[1])
      if (query[1] == '/') {
         this.activePath = '/index' || '/'
      } else {
         this.activePath = query[1]
      }
      console.log('isActive', this.activePath)

   },
   methods: {

      goUrlPage(url) {
         this.activePath = url
         this.$router.replace({ path: url }, () => { })
      },
      infoList() {
         var that = this
         that.$http({
            method: 'post',
            url: 'https://mars-players.cn/addons/official/api.index/config',
            // data:{
            //   id:'1'
            // }

         }).then(function (response) {
            that.info = response.data.data.site_logo
            that.logo_url = response.data.data.site_logo_url
            console.log(that.info);
            //   console.log('1111111111111111111111')
			var siteData = {
				beian: response.data.data.site_beian,
				support: response.data.data.site_technical_support
			}
			localStorage.setItem('basicTitle', response.data.data.basic_title)
			localStorage.setItem('siteData', JSON.stringify(siteData))
         })
            .catch(function (error) {
               console.log(error);
            });
      },
      goindex() {
         this.$router.replace({ path: '/index' }, () => { })
         this.Inv = 0

      },
      gohome() {
         this.$router.replace({ path: '/about' }, () => { })
         this.Inv = 1

      },
      goproduct() {
         this.$router.replace({ path: '/product' }, () => { })
         this.Inv = 2

      },
      goclub() {
         this.$router.replace({ path: '/club' }, () => { })
         this.Inv = 3
      },
      gojoin() {
         this.$router.replace({ path: '/join' }, () => { })
         this.Inv = 4
      },
      goother() {
         this.$router.replace({ path: '/other' }, () => { })
         this.Inv = 5
      },
   },
   components: {},
   mounted() {
      this.infoList()
   },
};
</script>
<style lang='less' scoped>
.container {
   width: 100%;
   height: 100px;

   //  background: #333;
   //  background: rgba(0, 0, 0, 0.3);
   ul {
      margin: 0 auto;
      // width: 1183px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
         width: 169px;
         height: 100%;
         list-style-type: none;
         font-family: 'PingFang SC';
         font-style: normal;
         font-weight: 600;
         font-size: 16px;
         color: #ffffff;
         text-align: center;
         line-height: 100px;
         cursor: pointer;

      }
   }

   .show {
      background-color: #F15A24;
   }
}</style>
<template>
 <div class='container'>
    <h3>联系我们</h3>
    <div class="downBox">
      <div class="leftBox">
        <ul>
          <li v-for="(item,index) in info.list" :key="index" @click="source(item)">
          <!-- <a :href="item.source_url" class="a-name"> -->
             <img :src="'https://mars-players.cn/'+item.image" alt="" >

            <!-- <img src="../assets//iamges/redbook.png" alt=""> -->
            <span>{{item.title }}</span>
          <!-- </a> -->
         
          </li>
          <!-- <li>
            <img src="../assets//iamges/weibo.png" alt="">
            <span>微博</span>
          </li> -->
          <!-- <li>
            <img src="../assets//iamges/postbox.png" alt="">
            <span>邮箱：Email Info@mars-players.com</span>
          </li> -->
          <!-- <li>
            <img src="../assets//iamges/tiktok.png" alt="">
            <span>抖音</span>
          </li> -->
          <!-- <li>
            <img src="../assets//iamges/jd.png" alt="">
            <span>京东</span>
          </li> -->
          <!-- <li>
            <img src="../assets//iamges/mobile.png" alt="">
            <span>电话：0631-3953100</span>
          </li> -->
        </ul>
      </div>
      <div class="rightBox">
        <div class="leftImg"  v-for="(item,index) in info.list2" :key="index">
          <img :src="'https://mars-players.cn/'+item.image" alt="">

          <!-- <img src="../assets/iamges/qiye.png" alt=""> -->
          <span>{{ item.title }}</span>
        </div>
        <!-- <div class="rightImg">
          <img src="../assets/iamges/website.png" alt="">
          <span>TMALL官网</span>
        </div> -->
      </div>
  </div>
	<div style="margin-top: 40px; text-align: center;">
		<a :href="support.url" target="_blank" style="color: #ccc;">技术支持：{{ support.name }}</a>
		<a :href="beian.url" target="_blank" style="color: #ccc; margin-left: 20px;">备案号：{{ beian.name }}</a>
	</div>
 </div>
 </template>
  <script>
 export default {
 data() {
  return {
    info:'',
	support: {},
	beian: {}
   };
  },
  created() {
  	var siteData = JSON.parse(localStorage.getItem('siteData'))
	this.support = {
		name: siteData.support[0],
		url: siteData.support[1]
	}
	this.beian = {
		name: siteData.beian[0],
		url: siteData.beian[1]
	}
  },
 methods: {
  infoList() {
      var that=this
      that.$http({
        method: 'post',
        url: 'https://mars-players.cn/addons/official/api.index/contact',
        // data:{
        //   id:'1'
        // }

      }).then(function (response) {
        that.info = response.data.data
        console.log(that.info);
      })
        .catch(function (error) {
          console.log(error);
        });
    },
    source(item){
      window.location.href=item.source_url
    }
  },
 components: {},
 mounted() {
   this.infoList()
 },
  };
 </script>
 <style lang='less' scoped>
 .container{
  width: 100%;
  // height: 200px;
  background-color:#282828;
  overflow: hidden;
  // padding-bottom:80px;
    h3{
    font-weight: 700;
    font-size: 24px;
    color: #ffffff;
    margin-top: 40px;
    box-sizing: border-box;
    padding-left: 320px;
    }
    .downBox{
      height: 100%;
      box-sizing: border-box;
      padding-left: 320px;
      display: flex;
      justify-content: flex-start;
      .leftBox{
        width: 1020px;
        // height: 100px;
        margin-right: 89px;
    ul{
      padding-left: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li{
        cursor: pointer;
        width: 318px;
        margin-top: 30px;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        &:nth-of-type(3n+3){
          margin-bottom: 0;
        }
        .a-name{
          display: block;
           cursor: pointer;
        width: 318px;
        margin-top: 30px;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        }
        img{
          width:29px;
          height: 29px;
          margin-right: 10px;
          align-items: center;

        }
        span{
          font-weight: 400;
          font-size: 16px;
          line-height: 29px;
          color: #ffffff;
        }
      }
    }
   }
   .rightBox{
      width: 300px;
      // height: 100px;
      display: flex;
      flex-direction: row;
      justify-content:flex-start;
      border-left: 1px solid #ffffff;
      margin-top: 30px;
      box-sizing: border-box;
      padding-left: 51px;
    div{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      color: #ffffff;
      font-weight: 400;
      font-size: 14px;
      margin-right: 27px;
      img{
      width: 110px;
      height: 110px;
      margin-bottom: 15px;
      }
    }
   }
  }

}
 
@media (min-width: 1000px) {
  .container {
      padding-bottom: 50px;
  }
}
@media (min-width: 1280px) {
  // .container {
  //   padding-bottom: 50px;
  // }
} 
</style>